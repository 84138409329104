import { createAction } from '@reduxjs/toolkit'

export const updateFetching = createAction<{ fetching: boolean }>('user/updateFetching')
export const updateConnected = createAction<{ connected: boolean }>('user/updateConnected')
export const updateAddress = createAction<{ address: string }>('user/updateAddress')
export const updateEthBalance = createAction<{ ethBalance: string }>('user/updateEthBalance')
export const updateRewardAmount = createAction<{rewardAmount: Number}>('user/updateRewardAmount')
export const updateStakedItems = createAction<{stakedItems: Number[]}>('user/stakedItems')
export const updateUnstakedItems = createAction<{unstakedItems:Number[]}>('user/unstakedItems')
export const updateGetApproved = createAction<{ isApproved: boolean }>('user/isApproved')
export const updateNFTBalance = createAction<{ nftBalance: number }>('user/nftBalance')
export const updateTokenBalance = createAction<{ tokenBalance: Number }>('user/tokenBalance')
export const updateMarsRiseBalance = createAction<{ marsriseBalance: string }>('user/updateMarsRiseBalance')
export const updateNFTAllowance = createAction<{ nftAllowance: string }>('user/updateNFTAllowance')
export const updateLotteryAllowance = createAction<{ lotteryAllowance: string }>('user/updateLotteryAllowance')