import { useEffect } from 'react'
import { useAppDispatch } from 'state'
import { updateEthBalance, updateRewardAmount, updateMarsRiseBalance, updateNFTAllowance, updateLotteryAllowance, updateStakedItems, updateUnstakedItems, updateGetApproved, updateNFTBalance, updateTokenBalance } from './actions'
import { useFastFresh } from 'hooks/useRefresh'
import { callBalanceOf, callAllowance } from 'utils/calls/token'
import { callGetApproved, callNFTBalance, callRewardAmount, callStakedItmes, callUnstakedItmes } from 'utils/calls/nft'
import { getNFTAddress, getLotteryAddress } from 'utils/addressHelper'
import { getMarsRiseContract, getTokenContract } from 'utils/contractHelper'
import { useWeb3 } from 'utils/useWeb3'

export const useEthBalance = (web3: any | undefined, address: string | undefined) => {
  const dispatch = useAppDispatch()
  const fastRefresh = useFastFresh()
  useEffect(() => {
    const getETHBalance = async () => {
      const result = await web3.eth.getBalance(address)
      dispatch(updateEthBalance({ ethBalance: result }))
    }
    if (web3 && address) {
      getETHBalance()
    }
  }, [web3, address, dispatch, fastRefresh])
}

export const useRewardAmount = (web3: any, address: string) => {
  const dispatch = useAppDispatch()
  const fastRefresh = useFastFresh()
  useEffect(() => {
    const getRewardAmount = async() => {
      const result = await callRewardAmount(web3, address)
      if (result) {
        dispatch(updateRewardAmount({rewardAmount: result as Number}))
      }
    }
    if (web3 && address) {
      getRewardAmount()
    }
  }, [web3, address, dispatch, fastRefresh])
}

export const useStakedItems = (web3: any, address: string) => {
  const dispatch = useAppDispatch()
  const fastRefresh = useFastFresh()
  useEffect(() => {
    const getStakedItems = async() => {
      const result = await callStakedItmes(web3, address)
      if (result.success) {
        dispatch(updateStakedItems({stakedItems: result.result as Number[]}))
      }
    }
    if (web3 && address) {
      getStakedItems()
    }
  }, [web3, address, dispatch, fastRefresh])
}

export const useUnstakedItems = (web3: any, address: string) => {
  const dispatch = useAppDispatch()
  const fastRefresh = useFastFresh()
  useEffect(() => {
    const getUnstakedItems = async() => {
      const result = await callUnstakedItmes(web3, address)
      if (result.success) {
        dispatch(updateUnstakedItems({unstakedItems: result.result as Number[]}))
      }
    }
    if (web3 && address) {
      getUnstakedItems()
    }
  }, [web3, address, dispatch, fastRefresh])
}

export const useNFTBalance = (web3: any, address: string) => {
  const dispatch = useAppDispatch()
  const fastRefresh = useFastFresh()
  useEffect(() => {
    const getNFTBalance = async() => {
      const result = await callNFTBalance(web3, address)
      if (result.success) {
        dispatch(updateNFTBalance({nftBalance: result.result}))
      }
    }
    if (web3 && address) {
      getNFTBalance()
    }
  }, [web3, address, dispatch, fastRefresh])
}

export const useApproved = (web3: any, address: string) => {
  const dispatch = useAppDispatch()
  const fastRefresh = useFastFresh()
  useEffect(() => {
    const getIsApproved = async() => {
      const result = await callGetApproved(web3, address)
      if (result.success) {
        dispatch(updateGetApproved({isApproved: result.result as boolean}))
      }
    }
    if (web3 && address) {
      getIsApproved()
    }
  }, [web3, address, dispatch, fastRefresh])
}

export const useTokenBalance = (web3: any, address: string) => {
  const dispatch = useAppDispatch()
  const fastRefresh = useFastFresh()
  useEffect(() => {
    const getTokenBalance = async() => {
      const result = await callBalanceOf(web3, address)
      // console.log("tokenBal", result)
      if (result.success) {
        dispatch(updateTokenBalance({tokenBalance: result.result}))
      }
    }
    if (web3 && address) {
      getTokenBalance()
    }
  }, [web3, address, dispatch, fastRefresh])
}

export const useNFTAllowance = (web3: any, address: string) => {
  const dispatch = useAppDispatch()
  const fastRefresh = useFastFresh()
  useEffect(() => {
    const getNFTAllowance = async () => {
      const result = await callAllowance(getTokenContract(web3), getNFTAddress(), address, web3)
      if (result) {
        dispatch(updateNFTAllowance({ nftAllowance: `${result}` }))
      }
    }
    if (web3 && address) {
      getNFTAllowance()
    }
  }, [web3, address, dispatch, fastRefresh])
}

export const useFetchUserData = (address: string) => {
  const web3 = useWeb3()
  useEthBalance(web3, address)
  useRewardAmount(web3, address)
  useStakedItems(web3, address)
  useUnstakedItems(web3, address)
  useApproved(web3, address)
  useNFTBalance(web3, address)
  useTokenBalance(web3, address)
  useNFTAllowance(web3, address)
}
