import React, { useEffect, useState } from 'react'
import BigNumber from 'bignumber.js'
import Swal from 'sweetalert2'
import {toast} from 'react-toastify'
import styled from 'styled-components'
import { useUserData, useConnectedManager } from 'state/user/hooks'
import { useNFTData } from 'state/nft/hooks'
import { TokenData } from 'state/nft'
import { getFullDisplayBalance } from 'utils/formatBalance'
import getTimePeriods from 'utils/getTimePeriod'
import { useWeb3 } from 'utils/useWeb3'
import { callApprove, callBalanceOf } from 'utils/calls/token'
import { getMarsRiseContract } from 'utils/contractHelper'
import { getNFTAddress } from 'utils/addressHelper'
import { callBoostSpaceMan, callClaim, callGetApproved, callSellBoostSpaceMan, callSetApprovalForAll, callStake, callStakedItmes, callUnstake, callUnstakedItmes } from 'utils/calls/nft'
import { ActionArea, ActionButton, ActionSecondaryButton, Card, CardHeader, ControlButton, ControlContent, CrossLine, DetailArea, DetailedCard, DetailTitle, DetailHeader, DetailValue, Divider, StakeCard, ImageArea, StakeTitle } from './styles'
import { useStakedItems } from 'state/user/fetch'

const BoostInfo = styled.div`
  margin: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
`
const StakePad = styled.div`
  margin: 10px;
  height: 50%;
  display: flex;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  @media only screen and (max-width: 576px) {
    display: block;
  }
`

const StakePanel = styled.div`
  width: 50%;
  @media only screen and (max-width: 576px) {
    width: 100%;
  }
`

const BoostImage = styled.img`
  width: 100%;
  margin: 2px;
  border-radius: 160px;
  border: 2px solid #4099ff;
  @media only screen and (max-width: 1200px) {
    width: 50px;
  }
`

const Level = styled.div`
  font-size: 26px;
  font-weight: 700;
  margin: 20px 15px;
  margin-bottom: 0;
`

const ActionButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 20px;
  @media only screen and (max-width: 1024px) {
    justify-content: center;
  }
`

interface DetailProps {
  title: string
  value: string
}

// interface TimerProps {
//   bostTime: number
// }

// const InitSelectedNFT = {
//   id: 100000,
//   boostLevel: '0',
//   nextBoostTime: '0',
// }

const StakingCard: React.FC = () => {
  const web3 = useWeb3()

  const [connected] = useConnectedManager()

  // @BEAST //

  // const [unstakedIds, setUnstakedIds] = useState([]);
  // const [stakedIds, setStakedIds] = useState([]);
  const [selectedUnstakedIds, setSelectedUnstakedIds] = useState<Number[]>([]);
  const [selectedStakedIds, setSelectedStakedIds] = useState<Number[]>([]);
  const [unstakedImages, setUnstakedImages] = useState<string[]>([]);
  const [stakedImages, setStakedImages] = useState<string[]>([]);
  const [pending, setPending] = useState(false);
  const [stakedItems, setStakedItems] = useState<Number[]>([])
  const [unstakedItems, setUnstakedItems] = useState<Number[]>([])
  const [isApproved, setIsApproved] = useState(false)
  const [rewardAmount, setRewardAmount] = useState<Number>(0);
  const [tokenBalance, setTokenBalance] = useState<Number>(0);

  const [userData] = useUserData()

  const { address } = userData

  useEffect(() => {
    const load = async() => {
      if (connected) {
        setStakedItems(userData.stakedItems);
        setUnstakedItems(userData.unstakedItems);
        setIsApproved(userData.isApproved);
        setRewardAmount((userData.rewardAmount.valueOf() / 10 ** 9));
        setTokenBalance((userData.tokenBalance.valueOf() / 10 ** 9));
        const ustitems = await getImageURL(userData.unstakedItems);
        setUnstakedImages(ustitems);
        const stitems = await getImageURL(userData.stakedItems);
        setStakedImages(stitems);
      }
    }
    load()
  }, [userData])

  useEffect(()=> {
    if(!connected) {
      setStakedItems([]);
      setUnstakedItems([]);
    }
  }, [connected])

  const getInitAmount = async () => {
    console.log('[Wallet] = ', address);
    setStakedItems([]);
    setUnstakedItems([]);
    if (!web3) {
      return;
    }
    // setLoading(true);

    let res = await callGetApproved(web3, address);
    if (res.success) {
      if (res.result) {
        setIsApproved(true);
      }
      else {
        setIsApproved(false);
      }
    }

    res = await callStakedItmes(web3, address);
    if (res.success) {
      setStakedItems(res.result);
    }

    // res = await getImageURL(res.nftIds);
    // setUnstakedImages(res);
    // console.log(res)

    res = await callUnstakedItmes(web3, address);
    if (res.success) {
      setUnstakedItems(res.result);
    }

    res = await callBalanceOf(web3, address);
    if (res.success) {
      setTokenBalance(res.result / 10 ** 9);
    }
    
    // res = await getImageURL(res.nftIds);
    // setStakedImages(res);

    // res = await getPendingReward();
    // if (res.success) {
    //   setRewardAmount(Number((res.reward / 10 ** 18).toFixed(4)));
    // }

    setSelectedStakedIds([]);
    selectedStakedIds.slice();
    setSelectedUnstakedIds([]);
    selectedUnstakedIds.slice();
    // setLoading(false);
  }

  const getTimeString = (value: number) => {
    if (value < 10) {
      return `0${value}`
    } else {
      return value
    }
  }

  const getImageURL = async(arr: Number[]) => {
    let imgArray: string[] = new Array(arr.length);
    for (let i = 0; i < arr.length; i++) {
      var val : string;
      await fetch("https://tomato-legislative-porpoise-876.mypinata.cloud/ipfs/QmeRSMg34A1HWGvudHEmGXdpEdXhqpzvoGGE7F6oeGjVHv/" + arr[i].toString().padStart(4, '0') + ".json")
      .then(responese => responese.json())
      .then((jsonData) => {
        val = jsonData.image
        imgArray[i] = val;
      })
    }
    return imgArray;
  }

  const IsSelected = (type:Number, tokenId:Number) => {
    var a = 0;
    const list = type === 0
            ? selectedUnstakedIds
            : selectedStakedIds;
    for (a = 0; a < list.length; a++) {
      if (list[a] === tokenId) {
        return true;
      }
    }
    return false;
  };

  const removeItemFromArray = (oldlist: Number[], tokenId: Number) => {
    var list = oldlist;
    var i = 0;
    for (i = 0; i < list.length; i++) {
      if (list[i] === tokenId) {
        list[i] = list[list.length - 1];
        list.pop();
        break;
      }
    }
    return list;
  };

  const unstakedImageClick = async(tokenId: Number, index: Number) => {
    // await fetch("https://ipfs.io/ipfs/" + collectionCIDs[tabIndex] + "/" + tokenId + ".json")
    // .then(responese => responese.json())
    // .then((jsonData) => {
    //   console.log(jsonData.attributes[0].value);
    // })
    if (await IsSelected(0, tokenId)) {
      let newList = removeItemFromArray(selectedUnstakedIds.slice(), tokenId);
      setSelectedUnstakedIds(newList);
    } else {
      var newList = selectedUnstakedIds.slice();
      newList.push(tokenId);
      setSelectedUnstakedIds(newList);
    }
    console.log("Beast", selectedUnstakedIds);
  }

  const stakedImageClick = async(tokenId: Number, index: Number) => {
    if (await IsSelected(1, tokenId)) {
      let newList = removeItemFromArray(selectedStakedIds.slice(), tokenId);
      setSelectedStakedIds(newList);
    } else {
      var newList = selectedStakedIds.slice();
      newList.push(tokenId);
      setSelectedStakedIds(newList);
    }
  }

  const handleStake = async() => {
    setPending(true);
    if (isApproved) {
      try {
        if (selectedUnstakedIds.length === 0) {
          toast.error("There is no selected NFTs for staking.")
          setPending(false);
          return;
        }
        let res = await callStake(web3, address, selectedUnstakedIds, '300000');
        if (res.success) {
          getInitAmount();
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Staked successfully.'
          })
        } else {
          toast.error("Transcation has been failed. " + res.err);
        }
      } catch (error) {
        toast.error("Transcation has been failed. " + error);
      }
    }
    else {
      try {
        let res = await callSetApprovalForAll(web3, address, '300000');
        console.log("SetApprovalForAll", res)
        if (res.success) {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Approved successfully.'
          });
        } else {
          toast.error("Transcation has been failed. " + res.err);
        }
      } catch (error) {
        toast.error("Transcation has been failed. " + error);
      }
    }
    setPending(false);
  }

  const handleUnstake = async() => {
    setPending(true);
    if (selectedStakedIds.length === 0) {
      toast.error("There is no selected NFTs for unstaking")
    } else {
      try {
        let res = await callUnstake(web3, address, selectedStakedIds, '300000');
        if (res.success) {
          getInitAmount();
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Unstaked successfully.'
          })
        } else {
          toast.error("Transcation has been failed. " + res.err);
        }
      } catch (error) {
        toast.error("Transcation has been failed. " + error);
      }
    }
    setPending(false);
  }

  const handleClaim = async() => {
    setPending(true);
    try {
      let res = await callClaim(web3, address)
      if (res.success) {
        getInitAmount();
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Unstaked successfully.'
        })
      } else {
        toast.error("Transcation has been failed. " + res.err);
      }
    } catch (error) {
      toast.error("Transcation has been failed. " + error);
    }
    setPending(false);
  }

  const Detail: React.FC<DetailProps> = ({ title, value }) => {
    return (
      <DetailedCard>
        <DetailTitle>{title}</DetailTitle>
        <DetailValue>{value}</DetailValue>
      </DetailedCard>
    )
  }

  const current = new Date()

  return (
    <Card>
      <CardHeader>GENERATE $OSPELL</CardHeader>
      {/* <BoostInfo>
        <DetailArea>
          <a href="https://mm.finance/swap?outputCurrency=0x5eb71485f0736d368ddc5f290ac217d2a877fcf9" target="_blank">
            <Detail title="Your $PEOPLE" value="0" />
          </a>
        </DetailArea>
        <DetailArea>
          <Detail title="Earned $PEOPLE" value={connected ? `${1}` : '0'} />
        </DetailArea>
      </BoostInfo> */}
      <StakePad>
        <StakePanel>
          <DetailArea style={{marginTop: "10px"}}>
            <a href="https://polygonscan.com/address/0x492C7D1eB0E1B603AFAbFdD649502eD85E0955C7" target="_blank">
              <Detail title="Your $OSPELL" value={connected? tokenBalance.toFixed(3) : '0'} />
            </a>
          </DetailArea>
          <DetailArea style={{marginTop: "10px"}}>
            <StakeTitle>
            <DetailHeader>Unstaked NFT</DetailHeader>
            </StakeTitle>
            <Divider />
            <StakeCard>
              {unstakedItems && unstakedItems.map((tokenID, index) => {
                const isSelected = IsSelected(0, tokenID)
                let image = "https://discoverilla.mypinata.cloud/ipfs/QmZBEBS4nJsXU5QbRoYSozFxyxJPK1p6K3RttwfvK5q5UA/" + tokenID + ".png"
                // let image = unstakedImages[index]
                return (
                  <div
                    style={{
                      width: "25%",
                      height: "fit-content",
                      marginLeft: 2,
                      marginRight: 2,
                      marginTop: 10,
                    }}
                    onClick={()=> unstakedImageClick(tokenID, index)}
                    key={index}
                  >
                    <BoostImage src={image} style={isSelected? {border: "4px solid #00ffff"}:{}}/>
                    <div
                      style={{
                        color: "white",
                        fontSize: "20px",
                        textAlign: "center",
                      }}
                    >
                      {tokenID}
                    </div>
                  </div>
                )
              })}
            </StakeCard>
          </DetailArea>
          <ActionButton onClick={handleStake} disabled={pending || !connected}>{isApproved? "Stake": "Approve"}</ActionButton>
        </StakePanel>
        <StakePanel>
          <DetailArea style={{marginTop: "10px"}}>
            <Detail title="Earned $OSPELL" value={connected ? rewardAmount.toFixed(3) : '0'} />
          </DetailArea>
          <DetailArea style={{marginTop: "10px"}}>
            <StakeTitle>
            <DetailHeader>Staked NFT</DetailHeader>
            </StakeTitle>
            <Divider />
            <StakeCard>
              {stakedItems && stakedItems.map((tokenID, index) => {
                const isSelected = IsSelected(1, tokenID)
                let image = "https://discoverilla.mypinata.cloud/ipfs/QmZBEBS4nJsXU5QbRoYSozFxyxJPK1p6K3RttwfvK5q5UA/" + tokenID + ".png"
                // let image = stakedImages[index]
                return (
                  <div
                    style={{
                      width: "25%",
                      height: "fit-content",
                      marginLeft: 2,
                      marginRight: 2,
                      marginTop: 10,
                    }}
                    onClick={()=> stakedImageClick(tokenID, index)}
                    key={index}
                  >
                    <BoostImage src={image} style={isSelected? {border: "4px solid #00ffff"}:{}}/>
                    <div
                      style={{
                        color: "white",
                        fontSize: "20px",
                        textAlign: "center",
                      }}
                    >
                      {tokenID}
                    </div>
                  </div>
                )
              })}
            </StakeCard>
          </DetailArea>
          <ActionButtons style={{display: "flex"}}>
            <ActionButton onClick={handleUnstake} disabled={pending || !connected}>Unstake</ActionButton>
            <ActionButton onClick={handleClaim} disabled={pending || !connected || rewardAmount === 0}>Claim</ActionButton>
          </ActionButtons>
        </StakePanel>
      </StakePad>
    </Card>
  )
}

export default StakingCard
