import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  updateFetching,
  updateAddress,
  updateEthBalance,
  updateConnected,
  updateNFTAllowance,
} from './actions'
import { AppState } from '../index'
import { UserState } from './index'

export function useFetchingManager(): [boolean, (_fetching: boolean) => void] {
  const dispatch = useDispatch()
  const fetching = useSelector<AppState, AppState['user']['fetching']>((state) => state.user.fetching)

  const setFetching = useCallback((_fetching: boolean) => {
    dispatch(updateFetching({ fetching: _fetching }))
  }, [dispatch])

  return [fetching, setFetching]
}

export function useConnectedManager(): [boolean, (_connected: boolean) => void] {
  const dispatch = useDispatch()
  const connected = useSelector<AppState, AppState['user']['connected']>((state) => state.user.connected)

  const setConnected = useCallback((_connected: boolean) => {
    dispatch(updateConnected({ connected: _connected }))
  }, [dispatch])

  return [connected, setConnected]
}

export function useAddressManager(): [string, (_fetching: string) => void] {
  const dispatch = useDispatch()
  const address = useSelector<AppState, AppState['user']['address']>((state) => state.user.address)

  const setAddress = useCallback((_address: string) => {
    dispatch(updateAddress({ address: _address }))
  }, [dispatch])

  return [address, setAddress]
}

export function useEthBalanceManager(): [string, (_ethBalance: string) => void] {
  const dispatch = useDispatch()
  const ethBalance = useSelector<AppState, AppState['user']['ethBalance']>((state) => state.user.ethBalance)

  const setEthBalance = useCallback((_ethBalance) => {
    dispatch(updateEthBalance({ ethBalance: _ethBalance }))
  }, [dispatch])

  return [ethBalance, setEthBalance]
}

// export function useMarsRiseBalanceManager(): [string, (_marsriseBalance: string) => void] {
//   const dispatch = useDispatch()
//   const marsriseBalance = useSelector<AppState, AppState['user']['marsriseBalance']>((state) => state.user.marsriseBalance)

//   const setMarsRiseBalance = useCallback((_marsriseBalance) => {
//     dispatch(updateMarsRiseBalance({ marsriseBalance: _marsriseBalance }))
//   }, [dispatch])

//   return [marsriseBalance, setMarsRiseBalance]
// }

export function useNFTAllowanceManager(): [string, (_nftAllowance: string) => void] {
  const dispatch = useDispatch()
  const nftAllowance = useSelector<AppState, AppState['user']['nftAllowance']>((state) => state.user.nftAllowance)

  const setNFTAllowance = useCallback((_nftAllowance) => {
    dispatch(updateNFTAllowance({ nftAllowance: _nftAllowance }))
  }, [dispatch])

  return [nftAllowance, setNFTAllowance]
}

// export function useLotteryAllowanceManager(): [string, (_lotteryAllowance: string) => void] {
//   const dispatch = useDispatch()
//   const lotteryAllowance = useSelector<AppState, AppState['user']['lotteryAllowance']>((state) => state.user.lotteryAllowance)

//   const setLotteryAllowance = useCallback((_lotteryAllowance) => {
//     dispatch(updateLotteryAllowance({ lotteryAllowance: _lotteryAllowance }))
//   }, [dispatch])

//   return [lotteryAllowance, setLotteryAllowance]
// }

export function useUserData(): [UserState] {
  const user = useSelector<AppState, AppState['user']>((state) => state.user)

  return [user]
}