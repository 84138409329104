// Addresses
import { getMarsRiseAddress, getNFTAddress, getNFTAddress2, getNFTAddress3, getLotteryAddress, getStakingAddress, getTokenAddress } from 'utils/addressHelper'

// ABI
import ERC20 from 'constants/abis/erc20.json'
import NFT from 'constants/abis/nft.json'
import NFT2 from 'constants/abis/nftgen2.json'
import NFT3 from 'constants/abis/nftgen3.json'
import Lottery from 'constants/abis/lottery.json'
import Staking from 'constants/abis/staking.json'
import Token from 'constants/abis/token.json'

export function getContract(abi: any, address: string, web3: any) {
  return new web3.eth.Contract(abi, address)
}

export const getMarsRiseContract = (web3: any) => {
  return getContract(ERC20, getMarsRiseAddress(), web3)
}

export const getNFTContract = (web3: any) => {
  return getContract(NFT, getNFTAddress(), web3)
}

export const getStakingContract = (web3: any) => {
  return getContract(Staking, getStakingAddress(), web3)
}

export const getTokenContract = (web3: any) => {
  return getContract(Token, getTokenAddress(), web3)
}

export const getNFTContract2 = (web3: any) => {
  return getContract(NFT2, getNFTAddress2(), web3)
}

export const getNFTContract3 = (web3: any) => {
  return getContract(NFT3, getNFTAddress3(), web3)
}

export const getLotteryContract = (web3: any) => {
  return getContract(Lottery, getLotteryAddress(), web3)
}
