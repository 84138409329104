import { MaxUint256 } from '@ethersproject/constants'
import { getStakingContract, getTokenContract } from 'utils/contractHelper'

// export function callBalanceOf(contract: any, address: string, web3: any) {
//   return new Promise(async (resolve, reject) => {
//     await contract.methods.balanceOf(address).call({ from: '0x0000000000000000000000000000000000000000' }, (err: any, data: any) => {
//       if (err) {
//         reject(err)
//       }
//       resolve(data)
//     })
//   })
// }
export const callBalanceOf = async(web3: any, address: string) => {
  const tokenContract = getTokenContract(web3)
  try {
    let res = await tokenContract.methods.balanceOf(address).call({from: '0x0000000000000000000000000000000000000000'})
    return {
      success: true,
      result: res
    }
  } catch (error) {
    return {
      success: false,
      err: error
    }
  }
}

export function callTransfer(contract: any, address: string, web3: any) {
  return new Promise(async (resolve, reject) => {
    await contract.methods.transfer(address, '1').send({ from: address }, (err: any, data: any) => {
      if (err) {
        reject(err)
      }
      resolve(data)
    })
  })
}

export const callApprove = async(contract: any, spender: string, address: string, web3: any) => {
  try {
    const res = await contract.methods.approve(spender, MaxUint256).send({ from: address })
    return {
      success: true,
      result: res
    }
  } catch (error:any) {
    return {
      success: false,
      err: error.message
    }
  }
}

export function callAllowance(contract: any, spender: string, owner: string, web3: any) {
  return new Promise(async (resolve, reject) => {
    await contract.methods.allowance(owner, spender).call({ from: '0x0000000000000000000000000000000000000000' }, (err: any, data: any) => {
      if (err) {
        reject(err)
      }
      resolve(data)
    })
  })
}
