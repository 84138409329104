import React, { useEffect, useState } from 'react'
import BigNumber from 'bignumber.js'
import styled from 'styled-components'
import { useUserData, useConnectedManager, useNFTAllowanceManager } from 'state/user/hooks'
import { useNFTData } from 'state/nft/hooks'
import { TokenData } from 'state/nft'
import { getFullDisplayBalance } from 'utils/formatBalance'
import getTimePeriods from 'utils/getTimePeriod'
import { useWeb3 } from 'utils/useWeb3'
import { callApprove } from 'utils/calls/token'
import { getMarsRiseContract, getTokenContract } from 'utils/contractHelper'
import { getNFTAddress } from 'utils/addressHelper'
import { callBoostSpaceMan, callSellBoostSpaceMan } from 'utils/calls/nft'
import { ActionArea, ActionButton, ActionSecondaryButton, Card, CardHeader, ControlButton, ControlContent, CrossLine, DetailArea, DetailedCard, DetailTitle, DetailValue, Divider, ImageArea } from './styles'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'

const BoostInfo = styled.div`
  margin: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
`

const BoostImage = styled.img`
  width: 150px;
  border-radius: 160px;
  border: 2px solid #4099ff;
`

const Level = styled.div`
  font-size: 26px;
  font-weight: 700;
  margin: 20px 15px;
  margin-bottom: 0;
`

const ActionButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 20px 20px;
`

interface DetailProps {
  title: string
  value: string
}

interface TimerProps {
  bostTime: number
}

const InitSelectedNFT = {
  id: 100000,
  boostLevel: '0',
  nextBoostTime: '0',
}

const BoostCard: React.FC = () => {
  const web3 = useWeb3()
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [periodDays, setPeriodDays] = useState(0)
  const [periodHours, setPeriodHours] = useState(0)
  const [periodMinutes, setPeriodMinutes] = useState(0)
  const [periodSeconds, setPeriodSeconds] = useState(0)
  const [selectedNFT, setSelectedNFT] = useState<TokenData>(InitSelectedNFT)
  const [pending, setPending] = useState(false);

  const [nftAllowance, setNFTAllowance] = useNFTAllowanceManager()
  const [connected] = useConnectedManager()

  useEffect(() => {
    if (!connected) {
      setSelectedNFT(InitSelectedNFT)
    }
  }, [connected])

  const [userData] = useUserData()
  const [nftData] = useNFTData()

  const { address, tokenBalance } = userData
  const { boostPrice, data } = nftData

  const isAllowed = new BigNumber(nftAllowance).gt(0)
  const enoughBalance = new BigNumber(tokenBalance.valueOf()).gte(new BigNumber(boostPrice))

  useEffect(() => {
    if (data.length === 0) {
      setSelectedNFT(InitSelectedNFT)
    }
  }, [data])

  const handleNext = () => {
    const nextIndex = selectedIndex + 1
    if (nextIndex >= data.length) {
      setSelectedIndex(data.length - 1)
    } else {
      setSelectedIndex(nextIndex)
    }
  }

  const handlePriv = () => {
    const nextIndex = selectedIndex - 1
    if (nextIndex < 0) {
      setSelectedIndex(0)
    } else {
      setSelectedIndex(nextIndex)
    }
  }

  useEffect(() => {
    if (selectedIndex < data.length && connected) {
      setSelectedNFT(data[selectedIndex])
    }
  }, [selectedIndex, data, connected, pending])

  const handleBoost = async() => {
    setPending(true);
    try {
      if (address && web3) {
        const result = await callBoostSpaceMan(web3, address, selectedNFT.id)
        if( result.success) {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Upgraded successfully.'
          })
        } else {
          toast.error("Transaction has been failed. " + result.err);
        }
      }  
    } catch (error) {
      toast.error("Transaction has been failed." + error);
    }
    setPending(false)
  }

  const handleSellBoost = async() => {
    setPending(true);
    try {
      if (address && web3) {
        const result = await callSellBoostSpaceMan(web3, address, selectedNFT.id)
        if( result.success) {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Sold level successfully.'
          })
        } else {
          toast.error("Transaction has been failed. " + result.err);
        }
      }  
    } catch (error) {
      toast.error("Transaction has been failed." + error);
    }
    setPending(false)
  }

  const handleEnable = async () => {
    setPending(true);
    try {
      if (address && web3) {
        const result = await callApprove(getTokenContract(web3), getNFTAddress(), address, web3)
        console.log('pooh, result = ', typeof result.result)
        if( result.success) {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Approved successfully.'
          })
          setNFTAllowance(parseInt(`${result.result}`, 16).toString())
        } else {
          toast.error("Transaction has been failed. " + result.err);
        }
      }  
    } catch (error) {
      toast.error("Transaction has been failed." + error);
    }
    setPending(false)
  }

  const getTimeString = (value: number) => {
    if (value < 10) {
      return `0${value}`
    } else {
      return value
    }
  }

  const Detail: React.FC<DetailProps> = ({ title, value }) => {
    return (
      <DetailedCard>
        <DetailTitle>{title}</DetailTitle>
        <DetailValue>{value}</DetailValue>
      </DetailedCard>
    )
  }

  const Timer: React.FC<TimerProps> = ({ bostTime }) => {
    const now = new Date()
    const secondsRemaining = bostTime - Math.round(now.getTime() / 1000)
    const count = secondsRemaining > 0 ? secondsRemaining : 0

    useEffect(() => {
      const timerId = setInterval(() => {
        const { days, hours, minutes, seconds } = getTimePeriods(count)
        setPeriodDays(days)
        setPeriodHours(hours)
        setPeriodMinutes(minutes)
        setPeriodSeconds(seconds)
      }, 1000)

      return () => clearInterval(timerId)
    }, [count])

    return <Detail title="Until Next Upgrade" value={`${getTimeString(periodDays)}:${getTimeString(periodHours)}:${getTimeString(periodMinutes)}:${getTimeString(periodSeconds)}`} />
  }

  const current = new Date()
  const boostEnable = Number(selectedNFT.nextBoostTime) <= Math.round(current.getTime() / 1000)
  const enoughLevel = Number(selectedNFT.boostLevel) > 0

  return (
    <Card>
      <CardHeader>Upgrade OrdinalWitches</CardHeader>
      <BoostInfo>
        <DetailArea>
          <a href="https://polygonscan.com/address/0x492C7D1eB0E1B603AFAbFdD649502eD85E0955C7" target="_blank">
            <Detail title="Your $OSPELL" value={connected ? `${getFullDisplayBalance(new BigNumber(tokenBalance.valueOf()), 9)}` : '0'} />
          </a>
          <Divider />
          <Timer bostTime={Number(selectedNFT.nextBoostTime)} />
        </DetailArea>
        <DetailArea>
          <Detail title="Your OWITCHES" value={connected ? `${data.length}` : '0'} />
          <Divider />
          <Detail title="Upgrade Price" value={`${getFullDisplayBalance(new BigNumber(boostPrice), 9)} $OSPELL`} />
        </DetailArea>
      </BoostInfo>
      {/* <Level>Level {selectedNFT.boostLevel}</Level> */}
      <ImageArea>
        {selectedNFT.id === InitSelectedNFT.id ? <BoostImage src={`images/boost.jpg`} alt="boost" /> : <BoostImage src={`https://discoverilla.mypinata.cloud/ipfs/QmZBEBS4nJsXU5QbRoYSozFxyxJPK1p6K3RttwfvK5q5UA/${selectedNFT.id}.png`} alt="boost" />}
        <CrossLine />
      </ImageArea>
      <Level>Level {selectedNFT.boostLevel}</Level>
      <div>
        <ActionArea>
          <ControlButton onClick={handlePriv} disabled={!connected || selectedIndex <= 0}>{`<`}</ControlButton>
          <ControlContent>{selectedNFT.id === InitSelectedNFT.id ? '?' : `#${selectedNFT.id}`}</ControlContent>
          <ControlButton onClick={handleNext} disabled={!connected || data.length === 0 || selectedIndex >= data.length - 1}>{`>`}</ControlButton>
        </ActionArea>
        <ActionButtons>
          {!isAllowed ? (
            <ActionButton onClick={handleEnable} disabled={!connected || pending}>
              Enable{' '}
            </ActionButton>
          ) : (
            <>
              <ActionButton onClick={handleBoost} disabled={!enoughBalance || !boostEnable || !connected || data.length === 0 || pending}>
                Upgrade
              </ActionButton>
              <ActionSecondaryButton onClick={handleSellBoost} disabled={!enoughLevel || !boostEnable || !connected || data.length === 0 || pending}>
                Sell Level
              </ActionSecondaryButton>
            </>
          )}
        </ActionButtons>
      </div>
    </Card>
  )
}

export default BoostCard
