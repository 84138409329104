import BigNumber from 'bignumber.js'
import { parseEther } from '@ethersproject/units'
import { BIG_TEN } from 'utils/bigNumber'
import { add, convertStringToNumber } from 'helpers/bignumber'
import { getNFTContract, getNFTContract2, getNFTContract3, getStakingContract } from '../contractHelper'
import { getStakingAddress } from 'utils/addressHelper'

export function callRewardAmount(web3:any, address: string) {
  const stakingContract = getStakingContract(web3)
  return new Promise(async(resolve, reject) => {
    await stakingContract.methods
      .pendingTotalReward(address)
      .call((err: any, data: any) => {
        if (err) {
          reject(err)
        }
        resolve(data)
      })
  })
}

// export function callGetApproved(web3:any, address: string) {
//   const stakingAddress = getStakingAddress()
//   const nftContract = getNFTContract(web3)
//   return new Promise(async(resolve, reject) => {
//     await nftContract.methods
//       .isApprovedForAll(address, stakingAddress)
//       .call((err: any, data: any) => {
//         if (err) {
//           reject(err)
//         }
//         resolve(data)
//       })
//   })
// }
export const callGetApproved = async(web3: any, address: string) => {
  const stakingAddress = getStakingAddress()
  const nftContract = getNFTContract(web3)
  try {
    let res = await nftContract.methods.isApprovedForAll(address, stakingAddress).call()
    return {
      success: true,
      result: res
    }
  } catch (error) {
    return {
      success: false
    }
  }
}

// export function callStakedItmes(web3:any, address: string) {
//   const stakingContract = getStakingContract(web3)
//   return new Promise(async(resolve, reject) => {
//     await stakingContract.methods
//       .getStakedItems(address)
//       .call((err: any, data: any) => {
//         if (err) {
//           reject(err)
//         }
//         resolve(data)
//       })
//   })
// }

export const callStakedItmes = async(web3:any, address: string) => {
  const stakingContract = getStakingContract(web3)
  try {
    let res = await stakingContract.methods.getStakedItems(address).call()
    return {
      success: true,
      result: res
    }
  } catch (error) {
    return {
      success: false
    }
  }
}

export const callUnstakedItmes = async(web3:any, address: string) => {
  const stakingContract = getStakingContract(web3)
  try {
    let res = await stakingContract.methods.getUnstakedItems(address).call()
    return {
      success: true,
      result: res
    }
  } catch (error) {
    return {
      success: false
    }
  }
}

// export function callUnstakedItmes(web3:any, address: string) {
//   const stakingContract = getStakingContract(web3)
//   return new Promise(async(resolve, reject) => {
//     await stakingContract.methods
//       .getUnstakedItems(address)
//       .call((err: any, data: any) => {
//         if (err) {
//           reject(err)
//         }
//         resolve(data)
//       })
//   })
// }

export const callNFTBalance = async(web3: any, address: string) => {
  const nftContract = getNFTContract(web3)
  try {
    let res = await nftContract.methods.balanceOf(address).call()
    return {
      success: true,
      result: res
    }
  } catch (error) {
    return {
      success: false
    }
  }
}

export function callMintPrice(web3: any) {
  const nftContract = getNFTContract(web3)
  return new Promise(async(resolve, reject) => {
    await nftContract.methods
      .mintPrice()
      .call((err: any, data: any) => {
        if (err) {
          reject(err)
        }
        resolve(data)
      })
  })
}

export function callTotalSupply(web3: any) {
  const nftContract = getNFTContract(web3)
  return new Promise(async(resolve, reject) => {
    await nftContract.methods
      .totalSupply()
      .call((err: any, data: any) => {
        if (err) {
          reject(err)
        }
        resolve(data)
      })
  })
}

export function callSpaceManInfoList(web3: any, index: number) {
  const nftContract = getNFTContract(web3)
  return new Promise(async(resolve, reject) => {
    await nftContract.methods
      .witchInfoList(index.toString())
      .call((err: any, data: any) => {
        if (err) {
          reject(err)
        }
        resolve(data)
      })
  })
}

// export function callMint(web3: any, address: string, price: string, count: number, gaslimit: string) {
//   const nftContract = getNFTContract(web3)
//   const cost = convertStringToNumber(price) * count

//   return new Promise(async(resolve, reject) => {
//     await nftContract.methods
//       .mintGENISIS(count)
//       .send({ from: address, value: new BigNumber(cost) }, (err: any, data: any) => {
//         if (err) {
//           reject(err)
//         }
//         resolve(data)
//       })
//   })
// }

export const callMint = async(web3: any, address: string, price: string, count: number) => {
  const nftContract = getNFTContract(web3)
  const cost = convertStringToNumber(price) * count
  try {
    await nftContract.methods.mintWitch(count).send({from: address, value: new BigNumber(cost)});
    return {
      success: true
    }
  } catch (error:any) {
    return {
      success: false,
      err: error.message
    }
  }
}

export const callStake = async(web3: any, address: string, tokenIds: Number[], gaslimit: string) => {
  const stakingContract = getStakingContract(web3)
  try {
    await stakingContract.methods.stake(tokenIds).send({from: address});
    return {
      success: true
    }
  } catch (error:any) {
    return {
      success: false,
      err: error.message
    }
  }
}

export const callUnstake = async(web3: any, address: string, tokenIds: Number[], gaslimit: string) => {
  const stakingContract = getStakingContract(web3)
  try {
    await stakingContract.methods.unstake(tokenIds).send({from: address})
    return {
      success: true
    }
  } catch (error:any) {
    return {
      success: false,
      err: error.message
    }
  }

  // return new Promise(async(resolve, reject) => {
  //   await stakingContract.methods
  //     .unstake(tokenIds)
  //     .send({ gasLimit: gaslimit, from: address }, (err: any, data: any) => {
  //       if (err) {
  //         reject(err)
  //       }
  //       resolve(data)
  //     })
  // })
}

export const callClaim = async(web3:any, address: string) => {
  const stakingContract = getStakingContract(web3)
  try {
    await stakingContract.methods.claim().send({from: address})
    return {
      success: true,
    }
  } catch (error:any) {
    return {
      success: false,
      err: error.message
    }
  }
}

export const callSetApprovalForAll = async(web3: any, address: string, gaslimit: string) => {
  const stakingAddress = getStakingAddress()
  const nftContract = getNFTContract(web3)
  try {
    await nftContract.methods.setApprovalForAll(stakingAddress, true).send({from:address})
    return {
      success: true
    }
  } catch (error:any) {
    return {
      success:false,
      err: error.message
    }
}

  // new Promise(async(resolve, reject) => {
  //   await nftContract.methods
  //     .setApprovalForAll(stakingAddress, true)
  //     .send({ gasLimit: gaslimit, from: address }, (err: any, data: any) => {
  //       if (err) {
  //         reject(err)
  //       }
  //       resolve(data)
  //     })
  // })
}

export function callClaimReward(web3: any, address: string, gaslimit: string) {
  const stakingContract = getStakingContract(web3)

  return new Promise(async(resolve, reject) => {
    await stakingContract.methods
      .claim()
      .send({ gasLimit: gaslimit, from: address }, (err: any, data: any) => {
        if (err) {
          reject(err)
        }
        resolve(data)
      })
  })
}

export function callMint2(web3: any, address: string, price: string, count: number, gaslimit: string) {
  const nftContract2 = getNFTContract2(web3)
  const cost = convertStringToNumber(price) * count

  return new Promise(async(resolve, reject) => {
    await nftContract2.methods
      .mintWitch(count.toString())
      .send({ gasLimit: gaslimit, from: address, value: new BigNumber(cost) }, (err: any, data: any) => {
        if (err) {
          reject(err)
        }
        resolve(data)
      })
  })
}

export function callMint3(web3: any, address: string, price: string, count: number, gaslimit: string) {
  const nftContract3 = getNFTContract3(web3)
  const cost = convertStringToNumber(price) * count

  return new Promise(async(resolve, reject) => {
    await nftContract3.methods
      .mintWitch(count.toString())
      .send({ gasLimit: gaslimit, from: address, value: new BigNumber(cost) }, (err: any, data: any) => {
        if (err) {
          reject(err)
        }
        resolve(data)
      })
  })
}

export function callBoostPrice(web3: any) {
  const nftContract = getNFTContract(web3)
  return new Promise(async(resolve, reject) => {
    await nftContract.methods
      .boostPrice()
      .call({ from: '0x0000000000000000000000000000000000000000' }, (err: any, data: any) => {
        if (err) {
          reject(err)
        }
        resolve(data)
      })
  })
}

export function callTokenOfOwnerByIndex(web3: any, address: string, index: number) {
  const nftContract = getNFTContract(web3)
  return new Promise(async(resolve, reject) => {
    await nftContract.methods
      .tokenOfOwnerByIndex(address, index.toString())
      .call((err: any, data: any) => {
        if (err) {
          reject(err)
        }
        resolve(data)
      })
  })
}

export const callBoostSpaceMan = async(web3: any, address: string, id: number) => {
  const nftContract = getNFTContract(web3)  
  try {
    await nftContract.methods.getMagicPower(id).send({ from: address })
    return {
      success: true
    }
  } catch (error:any) {
    return {
      success: false,
      err: error.message
    }
  }
}

export const callSellBoostSpaceMan = async(web3: any, address: string, id: number) => {
  const nftContract = getNFTContract(web3)  
  try {
    await nftContract.methods.sellMagicLevel(id).send({ from: address })
    return {
      success: true
    }
  } catch (error:any) {
    return {
      success: false,
      err: error.message
    }
  }
}